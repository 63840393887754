<template>
    <div id="contentwrap">
        <PageTitle />
        <div class="content-container">
            <header>
                <h1 class="title">Exclusive Guide</h1>
            </header>
            <section class="content-section">
                <div class="paragraph" data-aos="fade-up"  data-aos-duration="1000">
                    <p>
                        The Meredith Schlosser Team is proud to present an exclusive guide to Playa Vista, designed to provide you with unparalleled insights into the real estate landscape of the area.
                        Dive into a wealth of information, including neighborhood highlights, local amenities, and more, all tailored to enhance your home buying experience.
                    </p>
                </div>
                <div class="img-container" data-aos="fade-up"  data-aos-duration="1000">
                    <img src="/images/playa-vista-guide.jpg" alt="Playa Vista Guide">
                </div>
            </section>
            <section class="content-section-reverse">
                <div class="items-container">
                    <ul>
                        <li data-aos="fade-up"  data-aos-duration="1000">Mello-Roos</li>
                        <li data-aos="fade-up"  data-aos-duration="1000">Community Enhancement Fee</li>
                        <li data-aos="fade-up"  data-aos-duration="1000">Phase I vs Phase II</li>
                        <li data-aos="fade-up"  data-aos-duration="1000">Local Amenities</li>
                    </ul>
                </div>
                <div class="form-container" data-aos="fade-up"  data-aos-duration="1000">
                    <form
                        action="https://meredithschlosser.us7.list-manage.com/subscribe/post?u=a88ddc5ec59588a175d73b9c1&amp;id=3eecbe0c77&amp;f_id=0033d3e4f0"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        target="_blank"
                        novalidate>
                        <div id="subscripe">
                            <div class="form-container">
                                <h2>
                                    SECURE MY COPY
                                </h2>
                                <div class="form-elements">
                                    <input aria-label="Ennter your phone" type="text" v-model="name" name="FNAME" id="mce-FNAME" placeholder="Name *" required>
                                    <input aria-label="Enter your email address" type="email" name="EMAIL" v-model="email"  class="email" id="mce-EMAIL" placeholder="Email *" required>
                                    <input aria-label="Ennter your phone" type="text" name="PHONE" id="mce-PHONE" v-model="phone" placeholder="Phone *" required>
                                </div>
                                <div hidden=""><input type="hidden" name="tags" value="7253792"></div>
                                <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_a88ddc5ec59588a175d73b9c1_3eecbe0c77" tabindex="-1" value=""></div>
                                <input aria-label="Submit to subscribe our newsletter" type="submit" name="subscribe" value="Submit" id="mc-embedded-subscribe" class="button" v-bind:disabled="!formValid">
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>
    
<script>
import PageTitle from "../components/PageTitle"

export default {
    name: "Subscription",
    components: {
        PageTitle,
    },
    data(){
        return{
            name:'',
            email: '',
            phone: '',
        }
    },
    computed:{
        formValid(){
            return this.name && this.email && this.phone
        }
    },
    metaInfo: {
        title: 'Exclusive Guide ',
        titleTemplate: '%s Meredith Schlosser',
        meta: [
            {
                name: 'description',
                content: "About Meredith Schlosser - Real Estate Agent: Outgoing, Tireless, and a Commitment to Excellence. She's the Director of Aaroe International Luxury Properties and a member of the SFJ Group."
            },
            {
                name: 'author',
                content: "Meredith Schlosser"
            },
        ]
    }
}
</script>

<style lang="scss" scoped>
.content-container{
    padding: 80px 0;
}
header{
    text-align: center;
}
.content-section, .content-section-reverse{
    max-width: 1020px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px auto;
    padding-top: 60px;
    border-top: 1px solid #ccc;
}
.content-section-reverse{
    flex-direction: row-reverse;
}

.paragraph{
    p{
        padding: 0 40px;
    }
}

.img-container{
    padding-left: 40px;
    img{
        display: block;
        width: 100%;
    }
}

.items-container{
    margin-left: 40px;
    li{
        font-family: "Oswald",Helvetica,Arial,sans-serif;
        margin: 8px 0;
        font-size: 25px;
        font-weight: 500;
        text-transform: uppercase;
        list-style-image: url(/images/check.png);
        max-width: 280px;
        padding-left: 1px;
    }
}

.form-container{
    margin:0 40px;
}

#subscripe{
    background: #a9976f;
    .form-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 60px;
    }
    .form-elements{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    h2{
        color: #fff;
    }
}


.form-elements > input{
    background: #e1dbcd;
    border: none;
    padding-left: 20px;
    &::placeholder{
        color: #000;
    }
}

.button{
    color: #fff;
    background: #000;
    padding: 0 40px;
}

input[type=submit][disabled=disabled]{
    cursor: not-allowed;
    color: #ccc;
}

@media (max-width: $mobile) {
    .content-section, .content-section-reverse{
        flex-direction: column;
    }
    .img-container{
        padding: 0 40px;
    }
    #subscripe{
        .form-container{
            padding: 20px 10px;
            margin:0 10px;
        }
    }
    .form-elements{
        min-width: 340px;
    }

    .form-elements > input {
        margin: 0 20px 18px 20px;
    }
}
</style>